<template>
  <div class="share-dialog">
    <div v-if="imagePreview && !previewState">
      <cropper
        class="cropper"
        ref="cropper"
        :src="imagePreview"
        :stencil-props="stencilProps"
        :default-size="defaultSize"
        @change="change"
      />
    </div>
    <div class="dialog-buttons" v-if="!previewState">
      <mem-button class="btn-margin" btn-type="tertiary-dark" @click="onCancel">
        {{ $t("cancel") }}
      </mem-button>
      <mem-button
        v-if="!isLoadingPreview"
        btn-type="secondary-dark"
        @click="uploadImage"
      >
        {{ $t("preview") }}
      </mem-button>
      <div class="dialog-loader" v-if="isLoadingPreview">
        <v-progress-circular
          indeterminate
          color="#FFFFFF"
          size="36"
        ></v-progress-circular>
      </div>
    </div>
    <div class="image-preview" v-if="previewState">
      <img :src="previewImg" alt="preview" />
    </div>
    <div class="dialog-buttons" v-if="previewState">
      <mem-button class="btn-margin" btn-type="tertiary-dark" @click="back">
        {{ $t("back") }}
      </mem-button>
      <mem-button btn-type="secondary-dark" @click="share">
        {{ $t("share") }}
      </mem-button>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  props: ["options", "onCancel"],
  data: () => ({
    previewState: false,
    // image: new Image(),
    selectedArea: null,
    previewImg: null,
    isLoadingPreview: false,
    stencilSize: {
      width: 800,
      height: 420,
    },
    stencilProps: {
      aspectRatio: 1.9 / 1,
      movable: true,
      resizable: true,
      handlers: {
        eastNorth: true,
        north: false,
        westNorth: true,
        west: false,
        westSouth: true,
        south: false,
        eastSouth: true,
        east: false,
      },
    },
  }),
  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    imagePreview() {
      return localStorage.getItem("photoPreviewUrl");
      // return localStorage.getItem(`ph-${this.options.photo.athlete_image_id}-${this.options.resultId}`);
    },
  },
  methods: {
    ...mapActions(["getPhotoPreview", "getPhotoShareLink"]),
    preview() {
      this.previewState = true;
    },
    back() {
      this.previewState = false;
    },
    defaultSize() {
      return this.stencilSize;
    },
    async share() {
      let windowReference;
      if (this.options.type === "twitter") {
        windowReference = window.open();
      }
      const fd = new FormData();
      fd.append("image", this.selectedArea, "image");
      let link = await this.getPhotoShareLink({
        img: fd,
        resultId: this.options.resultId,
      });
      if (link && link.url) {
        if (this.options.type === "facebook") this.shareFacebook(link);
        if (this.options.type === "twitter") {
          let shareLink = `https://twitter.com/intent/tweet?text=&url=${location.origin}${link.url}&hashtags=spartanrace,spartanphotos`;
          windowReference.location = shareLink;
        }
      } else {
        console.log("Missing share link url", link);
      }
      this.onCancel();
    },
    shareFacebook(link) {
      let shareLink = "";
      if (this.isMobile) {
        shareLink = `https://www.facebook.com/dialog/share?app_id=488548495450963&display=popup&hashtag=%23spartanphotos&href=${
          location.origin
        }${link.url}&redirect_uri=${
          location.origin + "/results/" + this.options.resultId
        }`;
        document.location.href = shareLink;
      } else {
        shareLink = `${location.origin}${link.url}`;
        this.callFacebookUI(shareLink);
      }
    },
    shareTwitter(link) {
      let windowReference = window.open();
      let shareLink = `https://twitter.com/intent/tweet?text=&url=${location.origin}${link.url}&hashtags=spartanrace,spartanphotos`;
      windowReference.location = shareLink;
    },
    change({ canvas }) {
      canvas = this.getResizedCanvas(canvas, 800, 420);
      if (!HTMLCanvasElement.prototype.toBlob) {
        this.selectedArea = canvas.msToBlob();
      } else {
        canvas.toBlob((b) => {
          this.selectedArea = b;
        });
      }
    },
    async uploadImage() {
      this.isLoadingPreview = true;
      const fd = new FormData();
      fd.append("image", this.selectedArea, "image");
      const res = await this.getPhotoPreview({
        img: fd,
        resultId: this.options.resultId,
      });
      this.previewImg = URL.createObjectURL(res);
      this.isLoadingPreview = false;
      this.previewState = true;
    },
    callFacebookUI(link) {
      FB.ui(
        {
          method: "share",
          href: link,
          hashtag: "#spartanphotos",
          // quote: "Spartan FOREVER !!!",
        },
        function (response) {
          console.log(response, "response");
        }
      );
    },
    getResizedCanvas(canvas, newWidth, newHeight) {
      var tmpCanvas = document.createElement("canvas");
      tmpCanvas.width = newWidth;
      tmpCanvas.height = newHeight;

      var ctx = tmpCanvas.getContext("2d");
      ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        newWidth,
        newHeight
      );

      return tmpCanvas;
    },
  },
  mounted() {
    // this.image.crossOrigin = "anonymous";
    // this.image.src = `${this.options.photo.watermarked_preview}`;
    // console.log(this.image.src, "image eeee");
    // console.log(this.options, "Dialog options");
  },
  created() {},
  destroyed() {
    this.image = null;
    this.selectedArea = null;
    this.previewImg = null;
    this.previewState = false;
    localStorage.removeItem("photoPreviewUrl");
  },
};
</script>
<style lang="scss" scoped>
.share-dialog {
  padding: 16px;
  background: #383838;
  border-radius: 8px;
  // max-width: 582px;
  .image-preview {
    img {
      //   max-width: 535px;
      width: 100%;
    }
  }
  .dialog-buttons {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;
    padding: 16px 0 4px 0;
    .dialog-loader {
      width: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .btn-margin {
      margin-right: 6px;
    }
  }
}
</style>
